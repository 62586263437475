import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import './styles/global.scss';
import './index.css';
import { App as Canvas } from './Canvas';
import Overlay from './Overlay';
import reportWebVitals from './reportWebVitals';
import { Cursor } from './cursor';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Cursor />
    <section className="section-model">
      <Canvas />
    </section>
    <section className="section-model">
      <Overlay />
    </section>
  </>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
