import { useEffect, useRef } from 'react';

import { useSnapshot } from 'valtio';
import { state } from './store';
import { motion, AnimatePresence } from 'framer-motion';
import webGLFluidEnhanced from 'webgl-fluid-enhanced';
import CanvasTransparent from './CanvasTransparent';
import { Fade } from 'react-awesome-reveal';
import { ReactLenis, useLenis } from '@studio-freight/react-lenis';

export default function Overlay() {
  const snap = useSnapshot(state);

  const transition = { type: 'spring', duration: 0.8 };

  const config = {
    initial: { x: -100, opacity: 0, transition: { ...transition, delay: 0.5 } },
    animate: { x: 0, opacity: 1, transition: { ...transition, delay: 0 } },
    exit: { x: -100, opacity: 0, transition: { ...transition, delay: 0 } },
  };

  const lenis = useLenis(({ scroll }) => {
    // called every scroll
  });

  return (
    <div className="container">
      {/* <motion.header
        initial={{ opacity: 0, y: -120 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "sping", duration: 1.8, delay: 1 }}
      >
        <img src={Logo} alt="Logo" />
        <AiOutlineShopping size="3em" />
      </motion.header> */}
      <AnimatePresence>
        <ReactLenis root>
          {snap.intro ? (
            <Customizer key="custom" config={config} />
          ) : (
            <Customizer key="custom" config={config} />
          )}
        </ReactLenis>
      </AnimatePresence>
    </div>
  );
}

function Intro({ config }) {
  return (
    <motion.section {...config} key="main">
      <div className="section--container">
        <div>
          <h1>LET'S DO IT.</h1>
        </div>
        <div className="support--content">
          <div>
            <p>
              Visualize your exclusive <strong>Shirt</strong> with our brand-new
              3D customization tool.
            </p>
            <button
              style={{ background: 'black' }}
              onClick={() => (state.intro = false)}
            >
              CUSTOMIZE IT
            </button>
          </div>
        </div>
      </div>
    </motion.section>
  );
}

function Customizer({ config }) {
  const snap = useSnapshot(state);

  const colors = ['#ccc', '#edd02d', '#021164', '#af011d', '#015a32'];
  const decals = ['television', 'hannya', 'kitsune', 'retro', 'writing'];
  const canvasRef = useRef(null);
  useEffect(() => {
    webGLFluidEnhanced.simulation(canvasRef.current, {
      SIM_RESOLUTION: 256, // Resolution of the simulation grid
      DYE_RESOLUTION: 512, // Resolution of the dye grid
      CAPTURE_RESOLUTION: 512, // Resolution of captured frames
      DENSITY_DISSIPATION: 2, // Rate at which density dissipates
      VELOCITY_DISSIPATION: 0.2, // Rate at which velocity dissipates
      PRESSURE: 0.8, // Pressure value used in the simulation
      PRESSURE_ITERATIONS: 20, // Number of pressure iterations
      CURL: 1, // Curl value used in the simulation
      INITIAL: true, // Enables splats on initial load
      SPLAT_AMOUNT: 5, // Number of initial splats (Random number between n and n * 5)
      SPLAT_RADIUS: 1, // Radius of the splats
      SPLAT_FORCE: 2000, // Force applied by the splats
      SPLAT_KEY: 'Space', // Keyboard key to spawn new splats (empty to disable)
      SHADING: true, // Enables shading in the visualization
      COLORFUL: true, // Enables rapid changing of colors
      COLOR_UPDATE_SPEED: 10, // Speed of color update
      COLOR_PALETTE: [], // Custom color palette (empty by default, uses hex colors)
      HOVER: true, // Enables interaction on hover
      BACK_COLOR: '#000000', // Background color of the canvas
      TRANSPARENT: false, // Makes the canvas transparent if true
      BRIGHTNESS: 0.5, // Color brightness (Recommend lower than 1.0 if BLOOM is true)
      BLOOM: true, // Enables bloom effect
      BLOOM_ITERATIONS: 8, // Number of bloom effect iterations
      BLOOM_RESOLUTION: 256, // Resolution of the bloom effect
      BLOOM_INTENSITY: 0.8, // Intensity of the bloom effect
      BLOOM_THRESHOLD: 0.6, // Threshold for the bloom effect
      BLOOM_SOFT_KNEE: 0.7, // Soft knee value for the bloom effect
      SUNRAYS: true, // Enables sunrays effect
      SUNRAYS_RESOLUTION: 196, // Resolution of the sunrays effect
      SUNRAYS_WEIGHT: 1.0, // Weight of the sunrays effect
    });
  }, []);
  return (
    <motion.section key="custom" {...config}>
      <div className="customizer">
        <canvas
          ref={canvasRef}
          className="webgl"
          style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0 }}
        />
        <div className="section-0">
          <h1 className="logo-text">
            <Fade delay={1e3} cascade damping={1e-1}>
              Spectrum
            </Fade>
          </h1>
          <h5 className="text-wrapper">
            <Fade delay={1e3} cascade damping={1e-1}>
              Lighting Technologies
            </Fade>
          </h5>
          {/* <h1 className="logo-text">Spectrum</h1>
          <h5 className="text-wrapper">Lighting Technologies</h5> */}
        </div>

        <div className="section-1">
          <div className="section-1--content">
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              // animate={{ opacity: 1, y: 0 }}
              whileInView={{ opacity: 1, y: 0 }}
              // viewport={{ once: true }}
              transition={{ type: 'sping', duration: 0.8, delay: 0.8 }}
            >
              <p>Your ultimate solution provider in lighting.</p>
            </motion.div>
          </div>
        </div>
        <div className="section-2">
          <div id="model2">
            <CanvasTransparent />
          </div>
          <div className="section-2--content">
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              // animate={{ opacity: 1, y: 0 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: 'sping', duration: 0.8, delay: 0 }}
            >
              <p>
                In the right light, at the right time, Everything is
                Extraordinary...
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.section>
  );
}
