import { useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { easing } from 'maath';
import { useTransform, useScroll, useTime } from 'framer-motion';

import {
  useGLTF,
  Environment,
  Center,
  AccumulativeShadows,
  RandomizedLight,
} from '@react-three/drei';

import { useSnapshot } from 'valtio';
import { state } from './store';
export const App = ({ position = [0, 0, 0.25], fov = 70 }) => (
  <Canvas
    shadows
    gl={{ preserveDrawingBuffer: false }}
    camera={{ position, fov }}
    eventSource={document.getElementById('root')}
    eventPrefix="client"
    id="mainModel"
    className="main-model"
    style={{ zIndex: 2, height: '100vh', padding: '0rem' }}
  >
    <ambientLight intensity={0.5} />
    <Environment preset="city" />

    <CameraRig>
      {/* <Backdrop /> */}
      <Center>
        <Shirt />
      </Center>
    </CameraRig>
  </Canvas>
);

function Shirt(props) {
  const model = useGLTF('/models/spectrum.glb');

  return <primitive object={model.scene} position-y={0} rotation-y={0} />;
}

function CameraRig({ children }) {
  const group = useRef();
  const snap = useSnapshot(state);

  // document.addEventListener("mousewheel", onDocumentMouseWheel, false);
  // function onDocumentMouseWheel(event) {
  //   var dir = Math.sign(event.deltaY);
  //   console.log(dir);
  //   if (dir > 0) {
  //     document.querySelector("#mainModel").style.position = "fixed";
  //     document.querySelector("#mainModel").style.height = "150vh";
  //   } else {
  //     document.querySelector("#mainModel").style.position = "relative";
  //     document.querySelector("#mainModel").style.height = "100vh";
  //   }
  // }
  useFrame((state, delta) => {
    easing.damp3(
      state.camera.position,
      [snap.intro ? -state.viewport.width / 4 : 0, 0, 2],
      0.25,
      delta
    );
    easing.dampE(
      group.current.rotation,
      [state.pointer.y / 10, -state.pointer.x / 5, 0],
      0.25,
      delta
    );
  });
  // useFrame((state) => {
  //   // state.camera.position.setFromSphericalCoords(
  //   //   distance.get(),
  //   //   yAngle.get(),
  //   //   time.get() * 0.0005
  //   // );
  //   state.camera.updateProjectionMatrix();
  //   state.camera.lookAt(0, 0, 0);
  // });
  return <group ref={group}>{children}</group>;
}

useGLTF.preload('/models/spectrum.glb');
// ["/television", "hannya", "kitsune", "retro", "writing"].forEach(
//   useTexture.preload
// );
