import { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { easing } from 'maath';

import {
  useGLTF,
  Environment,
  Center,
  AccumulativeShadows,
  RandomizedLight,
  useTexture,
  OrbitControls,
} from '@react-three/drei';

import { useSnapshot } from 'valtio';
import { state } from './store';

export const CanvasTransparent = ({ position = [0, 0, 1.5], fov = 90 }) => (
  <Canvas
    shadows
    gl={{ preserveDrawingBuffer: true }}
    camera={{ position, fov }}
    eventSource={document.getElementById('model2')}
    eventPrefix="client"
    style={{ zIndex: 1, height: '512px', width: '100%', padding: '0rem' }}
  >
    <CameraRig>
      {/* <ambientLight intensity={0.5} />
      <directionalLight /> */}
      {/* <Environment preset="lobby" /> */}
      <OrbitControls
        target={[0, 0, 0]}
        autoRotate
        autoRotateSpeed={3}
        enablePan={false}
        enableZoom={false}
        // enableDamping={false}
        enableRotate={false}
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 2}
      />
      <Center>
        {/* <ambientLight intensity={1} /> */}
        {/* <directionalLight position={[5, 10, 7.5]} intensity={1} /> */}
        <directionalLight position={[15, 10, 15]} intensity={0.5} />
        <directionalLight position={[-15, 10, -15]} intensity={0.5} />
        <directionalLight position={[-15, 0, 0]} intensity={0.5} />
        <Shirt />
      </Center>
    </CameraRig>
  </Canvas>
);

function Shirt(props) {
  const model = useGLTF('/models/spectrum_trans.glb');
  return <primitive object={model.scene} position-y={0} rotation-y={0} />;
}

function CameraRig({ children }) {
  const group = useRef();
  const snap = useSnapshot(state);

  //   useFrame((state, delta) => {
  //     easing.damp3(
  //       state.camera.position,
  //       [snap.intro ? -state.viewport.width / 4 : 0, 0, 2],
  //       0.25,
  //       delta
  //     );
  //     easing.dampE(
  //       group.current.rotation,
  //       [state.pointer.y / 10, -state.pointer.x / 5, 0],
  //       0.25,
  //       delta
  //     );
  //   });
  return <group ref={group}>{children}</group>;
}

useGLTF.preload('/models/spectrum_trans.glb');
// ["/television", "hannya", "kitsune", "retro", "writing"].forEach(
//   useTexture.preload
// );
export default CanvasTransparent;
